<template>
  <section class="select-product">
    <div class="header-product">
      <div class="slider-product">
        <img :src="receiveProduct !== null ? receiveProduct.images[0].url : ''"
             :alt="receiveProduct !== null ? receiveProduct.images[0].originalName : ''">
      </div>
      <div class="product-description">
        <h2 class="title">{{ receiveProduct !== null ? receiveProduct.title : '' }}</h2>
        <h4 class="type-products">Single roses</h4>
        <div class="price-product">
          <h4 class="price">€ {{ receiveProduct !== null ? (receiveProduct.price/100) * receiveProduct.counter : '' }}</h4>
          <h6 class="type-price">per rose</h6>
        </div>
        <div class="container">
          <div class="product-counter">
            <div class="minus" @click="decreaseCounter()"><img src="@/assets/img/SelectProduct/minus.svg" alt=""></div>
            <div class="counter" v-if="receiveProduct !== null">{{ receiveProduct.counter }}</div>
            <div class="plus" @click="increaseCounter()"><img src="@/assets/img/SelectProduct/plus.svg" alt=""></div>
          </div>
          <div class="container-for-button">
            <div class="favorites">
              <img src="@/assets/img/SelectProduct/like.svg" alt="">
            </div>
            <div class="btn-add-to-card" @click="addToBasket(receiveProduct)">
              <img src="@/assets/img/SelectProduct/Cart.svg" alt="">
              <h6>Add to cart</h6>
            </div>
          </div>
        </div>
        <div class="description">
          <p>
           {{ receiveProduct !== null ? receiveProduct.description : '' }}
          </p>
        </div>
        <div class="product-description-list">
          <ul class="list">
            <li class="list__item">
              <div class="list__item__content">
                <img src="@/assets/img/SelectProduct/Map.svg" alt="">
                <h6>ecuador</h6>
              </div>
            </li>
            <li class="list__item">
              <div class="list__item__content">
                <img src="@/assets/img/SelectProduct/Clock.svg" alt="">
                <h6>10-12 days</h6>
              </div>
            </li>
            <li class="list__item">
              <div class="list__item__content">
                <img src="@/assets/img/SelectProduct/Arrow.svg" alt="">
                <h6>60-70cm</h6>
              </div>
            </li>
            <li class="list__item">
              <div class="list__item__content">
                <img src="@/assets/img/SelectProduct/Rose.svg" alt="">
                <h6>5.0-5.5 cm</h6>
              </div>
            </li>
          </ul>
        </div>
        <div class="product-extra">
          <h4 class="title">Extra</h4>
          <ul class="list">
            <li class="list__item">
              <div class="list__item__content">
                <div class="content-header">
                  <div class="img-container">
                    <img src="@/assets/img/SelectProduct/User.svg" alt="">
                  </div>
                  <h6>TAV</h6>
                </div>
                <div class="content-main">
                  <p>For whom is this bouquet?</p>
                </div>
                <div class="content-footer">
                  <div class="price-add">free</div>
                  <div class="cart-add" @click="openModal()">add</div>
                </div>
              </div>
            </li>
            <li class="list__item">
              <div class="list__item__content">
                <div class="content-header">
                  <div class="img-container">
                    <img src="@/assets/img/SelectProduct/Pen.svg" alt="">
                  </div>
                  <h6>Card</h6>
                </div>
                <div class="content-main">
                  <p>Write personal message</p>
                </div>
                <div class="content-footer">
                  <div class="price-add">€ 0.99</div>
                  <router-link :to="{name:'cards'}">
                    <div class="cart-add">add</div>
                  </router-link>
                </div>
              </div>
            </li>
            <li class="list__item">
              <div class="list__item__content">
                <div class="content-header">
                  <div class="img-container">
                    <img src="@/assets/img/SelectProduct/gift.svg" alt="">
                  </div>
                  <h6>Gifts</h6>
                </div>
                <div class="content-main">
                  <p>Add teddy bear, chocolate, ect.</p>
                </div>
                <div class="content-footer">
                  <div class="price-add">From € 8.99 </div>
                  <div class="cart-add" @click="transitionToGifts">add</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="advantages">
      <ul class="list">
        <li class="list__item">
          <div class="list__item__content">
            <img src="@/assets/img/SelectProduct/knife.svg" alt="">
            <h5 class="title">Cut flowers diagonally</h5>
            <p class="description">
              Cut flowers diagonally Flowers can then better absorb enough water.
              Also make sure that no leaves are hanging or coming into the water to prevent bacteria.
            </p>
          </div>
        </li>
        <li class="list__item">
          <div class="list__item__content">
            <img src="@/assets/img/SelectProduct/flowers.svg" alt="">
            <h5 class="title">Take cleat fitting vase</h5>
            <p class="description">
              Prevent bacteria in the water and this gives the stems space.
              Do not forget to add the cut flower food.
            </p>
          </div>
        </li>
        <li class="list__item">
          <div class="list__item__content">
            <img src="@/assets/img/SelectProduct/wind.svg" alt="">
            <h5 class="title">Not on the draft</h5>
            <p class="description">
              Not on a draft by the stove or next to fruit. The wind, heat
              and ethylene of the fruit make flowers relax faster.
            </p>
          </div>
        </li>
      </ul>
    </div>
    <TavModal ref="tav" />
  </section>
</template>

<script>
export default {
  name: "PageProduct",
  data(){
    return{
    }
  },
  props:{
    openBasket:{
      type:Function
    }
  },
  watch:{
    '$route.params.productId':{
      handler(productId){
        this.$store.dispatch('getSelectProduct',productId)
      },
      deep:true,
      immediate:true,
    }
  },
  computed:{
    receiveProduct(){
      if(this.$store.getters['receiveSelectProduct'] !== null){
        let vm = this
        console.log(vm.$loading(false));
      }
      return this.$store.getters['receiveSelectProduct'];
    }

  },
  components:{
    TavModal: () => import('@/components/Modals/TAVModal/TavModal.vue')
  },
  methods:{
    increaseCounter(){
      this.receiveProduct.counter++
    },
    decreaseCounter(){
      if(this.receiveProduct.counter > 1){
        this.receiveProduct.counter--
      }
    },
    transitionToGifts(){
      this.$router.push(`/selectProduct/&${this.$route.params.productId}/gifts`);
    },
    transitionToCards(){
      this.$router.push('/cards');
    },
    openModal(){
     this.$refs.tav.open()
    },
    addToBasket(item){
      this.$store.dispatch('addToBasket',{...item,customId:Date.now()});
      this.openBasket();
    }
  },
  created() {
    let vm = this
    console.log(vm.$loading(true));
  }
}
</script>

<style scoped lang="scss">
@import "SelectProduct";
</style>